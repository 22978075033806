import {Outlet} from 'react-router-dom'
import styled from 'styled-components'
import Notifications from '../Notifications/Notifications'
import {colors} from '../../style'
import Sidebar from './Sidebar'

const Layout = () => {
    return(
        <Wrapper>
            <Sidebar/>
            <Main>
                <Outlet/>
            </Main>
            <Notifications/>
        </Wrapper>
    )
}

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  background-color: ${colors.bg};
`

const Main = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`

export default Layout
