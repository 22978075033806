interface ILogoIconProps {
    width?: number
    height?: number
}

const LogoIcon = ({ width = 50, height = 50 }: ILogoIconProps) => (
    <svg xmlns={"http://www.w3.org/2000/svg"} width={width} height={height} viewBox={"0 0 120.97 120.97"}>
        <path d={"M1021,600.77V491a11.17,11.17,0,0,0-11.18-11.18H900l32.41,32.41a11.18,11.18,0,0,1,0,15.82L900,560.44l40.32,40.33,32.41-32.42a11.18,11.18,0,0,1,15.82,0Z"}
              transform={"translate(-900.02 -479.8)"} fill={"#23bf1f"}/>
        <path d={"M980.66,501.41a18.71,18.71,0,1,0,18.71,18.71,18.71,18.71,0,0,0-18.71-18.71M1021,600.77V559.26a11.18,11.18,0,0,0-11.18-11.18H951.52a11.18,11.18,0,0,0-11.18,11.18v41.51l32.41-32.42a11.18,11.18,0,0,1,15.82,0Z"}
            transform={"translate(-900.02 -479.8)"} fill={"#000000"}/>
    </svg>
)

export default LogoIcon
