import styled from 'styled-components'
import {Link, NavLink} from 'react-router-dom'
import LogoIcon from '../Icons/LogoIcon/LogoIcon'
import { Grid1x2, Clipboard } from '@styled-icons/bootstrap'
import {colors} from '../../style'

const Sidebar = () => (
    <Wrapper>
        <Logo>
            <Link to={"/"}>
                <LogoIcon/>
            </Link>
        </Logo>
        <Menu>
            <LinkItem>
                <NavLink to={"/"} className={({ isActive }) => (isActive ? " active" : "")}><Grid1x2/></NavLink>
            </LinkItem>
            <LinkItem>
                <NavLink to={"/functions"} className={({ isActive }) => (isActive ? " active" : "")}><Clipboard/></NavLink>
            </LinkItem>
        </Menu>
        <Action>

        </Action>
    </Wrapper>
)

const Wrapper = styled.div.attrs({
    className: "h-full flex flex-col justify-between items-center"
})`
  width: 100px;
`

const Logo = styled.div.attrs({
    className: "mt-5"
})``

const Menu = styled.ul.attrs({
    className: "w-full flex flex-col items-center"
})``

const Action = styled.div.attrs({
    className: "mb-5"
})``

const LinkItem = styled.li.attrs({
    className: "mb-4"
})`
  & > a {
    width: 50px;
    height: 50px;
    border-radius: 99999px;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${colors.green};
    transition: .2s all;
  }
  
  & > a.active, & > a:hover {
    background-color: ${colors.green};
    color: white;
  }
  
  & > a > svg {
    width: 20px;
    height: 20px;
  }
`

export default Sidebar
