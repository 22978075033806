import {useCallback, useState} from 'react'
import {useSpring} from 'react-spring'
import styled from 'styled-components'
import {animated} from 'react-spring'
import {colors} from '../../style'
import { ChevronRight } from '@styled-icons/boxicons-regular'
import NotificationItem from './NotificationItem'
import dayjs from 'dayjs';

function wait(milliseconds: number) {
    return new Promise(resolve => setTimeout(resolve, milliseconds));
}

const mockNotificationData = [
    {
        id: 3,
        title: "Review question 3",
        avatar: "https://i.pravatar.cc/150?img=60",
        user: "Tim den Hoven",
        when: dayjs().add(20, 'day').toDate()
    },
    {
        id: 1,
        title: "Review question 1",
        avatar: "https://i.pravatar.cc/150?img=58",
        user: "Gerard de Jong",
        when: dayjs().add(5, 'day').toDate()
    }
]

const styleConfig = {
    base: 35,
    full: 450,
    initialToggle: true
}

const Notifications = () => {
    const [toggle, setToggle] = useState<boolean>(styleConfig.initialToggle)

    const styles = useSpring({
        to: useCallback(async (next) => {
            if (toggle) {
                await wait(300)
                await next({width: styleConfig.base})
                // await next({transform: `translateX(${styleConfig.full - styleConfig.base}px)`})
            } else {
                await next({width: styleConfig.full})
                // await next({transform: 'translateX(0px)'})
            }
        }, [toggle])
    })

    const iconStyles = useSpring({
        transform: toggle ? 'rotate(180deg)' : 'rotate(0deg)'
    })

    const contentStyle = useSpring({
        opacity: toggle ? '0' : '1',
        delay: toggle ? 0 : 800,
        config: {
            duration: toggle ? 100 : 500
        }
    })

    return (
        <Wrapper style={styles}>
            <Button onClick={() => setToggle(!toggle)}>
                <Icon style={iconStyles}>
                    <ChevronRight/>
                </Icon>
            </Button>
            <Content style={{ ...contentStyle, visibility: contentStyle.opacity.to(o => o === '0' ? 'hidden' : 'visible') }}>
                <Title>Notifications</Title>
                <ItemsWrapper>
                    {
                        mockNotificationData.map((value, index) => <NotificationItem key={`home-notification-item-${index}`} {...value}/>)
                    }
                </ItemsWrapper>
            </Content>
        </Wrapper>
    )
}

const Wrapper = styled(animated.div).attrs({
    className: "p-7"
})`
  background-color: white;
  border-radius: 30px 0 0 30px;
  //padding: 30px;
  position: relative;
  // width: ${styleConfig.full}px;
  width: ${styleConfig.initialToggle ? styleConfig.base : styleConfig.full}px;
  // transform: translateX(${styleConfig.initialToggle ? styleConfig.full - styleConfig.base : 0}px);
`

const Button = styled(animated.button)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -30px;
  width: 60px;
  height: 60px;
  border-radius: 99999px;
  background-color: ${colors.green};
`

const Icon = styled(animated.div)`
  & > svg {
    width: 40px;
    height: auto;
    color: white;
  }
`

const Content = styled(animated.div).attrs({
    className: ""
})``

const Title = styled.h1.attrs({
    className: "text-1xl font-bold leading-7 text-gray-900 sm:text-2xl sm:truncate w-full h-12"
})``

const ItemsWrapper = styled.div.attrs({
    className: "relative grid gap-6"
})`
  border-color: #EBEBEB;
`

export default Notifications
