import React from 'react'
import {ErrorBoundary} from 'react-error-boundary'
import {QueryClientProvider} from 'react-query'
import {queryClient} from './lib/react-query'
import Router from './routes/router'

const ErrorFallback = () => (
    <div
        className={"text-red-500 w-screen h-screen flex flex-col justify-center items-center"}
        role={"alert"}
    >
        <h2 className={"text-lg font-semibold"}>Ooops, something went wrong :( </h2>
        <button className={"mt-4"} onClick={() => window.location.assign(window.location.origin)}>
            Refresh
        </button>
    </div>
)

const App = () => {
    return(
        <ErrorBoundary FallbackComponent={ErrorFallback}>
            <QueryClientProvider client={queryClient}>
                <Router/>
            </QueryClientProvider>
        </ErrorBoundary>
    )
}

export default App
