import {BrowserRouter, Routes, Route, Navigate} from 'react-router-dom'
import Layout from '../components/Layout/Layout'
import Home from '../pages/Home/Home'
import FunctionsIndex from '../pages/Functions/FunctionsIndex/FunctionsIndex'

const Router = () => (
    <BrowserRouter>
        <Routes>
            <Route path={"/"} element={<Layout/>}>
                <Route index element={<Home/>}/>
                <Route path={"functions"}>
                    <Route index element={<FunctionsIndex/>}/>
                </Route>
                <Route path={"*"} element={<Navigate to={"/"} replace />}/>
            </Route>
        </Routes>
    </BrowserRouter>
)

export default Router
